<template>
  <leisure-profile-component />
</template>

<script>
import LeisureProfileComponent from "@/components/restaurants-leisures/LeisureProfileComponent";

export default {
  name: "LeisuresProfile",
  title: "Perfil del Leisure | Baja California Health Tourism",
  components: { LeisureProfileComponent },
};
</script>

<style scoped></style>
